<template>
  <div class="home">
    <main class="main" data-v-109a143a="">
      <div data-v-109a143a="">
        <div data-v-a32e13b0="" class="lx-banner-container">
          <div
            data-v-a32e13b0=""
            class="lx-content-container items-center mt-8 mb-10 lg:flex-row lg:justify-between lg:items-center lg:mt-10"
          >
            <section
              data-v-a32e13b0=""
              class="text-center mb-4 lg:text-left lg:mb-0"
            >
              <!---->
              <h3 data-v-a32e13b0="" class="lx-banner-title">
                <span data-v-a32e13b0="" class="">百宸ERP </span
                ><span data-v-a32e13b0="" class="hight-light"
                  >行业市占有率第一</span
                >
              </h3>
              <!---->
              <p
                data-v-a32e13b0=""
                class="text-xs lg:text-lg font-light text-textContent mt-[6px] mb-8"
              >
                50万+跨境卖家的一致选择，支持20+主流跨境平台，助力卖家全球出海
              </p>
              <el-button size="large" type="primary" @click="tryout()"
                >立即免费试用</el-button
              >
              <el-button size="large" @click="tryout()" plain
                >查看成功案例</el-button
              >
              <p data-v-a32e13b0="" class="source-tip">
                来源：艾瑞咨询《2024年中国跨境电商软件服务行业报告》
              </p>
            </section>
            <section data-v-a32e13b0="" class="banner-img">
              <video
                data-v-a32e13b0=""
                id="videoPlay"
                src="@/assets/3c8c0d4eec4442c3c7b492bd2440fb1e.mp4"
                webkit-playsinline="true"
                playsinline="true"
                x5-playsinline="true"
                x5-video-player-fullscreen=""
                width="100%"
                autoplay="autoplay"
                loop="loop"
                muted="muted"
                disablepictureinpicture=""
                preload=""
                class="banner-main-img banner-video"
              ></video>
              <!---->
              <!---->
              <!---->
            </section>
          </div>
          <div data-v-a32e13b0="" class="video-con">
            <div data-v-a32e13b0="" id="lx-player">
              <i data-v-a32e13b0="" class="close-video iconfont icon-close"></i>
            </div>
          </div>
        </div>

        <!-- <div class="w-full max-w-7xl mx-auto px-15px -mt-10 lg:-mt-13 lg:px-0">
          <div data-v-13cd1c6f="" class="box">
            <div class="carousel-container">
              <div class="carousel" ref="carousel">
                <img
                  v-for="(image, index) in images"
                  :key="index"
                  :src="require('@/assets/authlogo' + image.src)"
                  :style="{ width: image.width }"
                />
              </div>
            </div>
          </div>
        </div> -->

        <div class="my-20 max-w-7xl mx-auto px-[15px] lg:px-0 lg:my-30">
          <div
            data-v-57c9ab6d=""
            class="flex justify-center flex-col items-center lx-middle-title"
          >
            <h3
              data-v-57c9ab6d=""
              class="lx-title w-full"
              style="text-align: center"
            >
              核心功能
            </h3>
            <div
              data-v-57c9ab6d=""
              class="max-w-840px text-sm px-15px lg:text-lg lg:px-0 font-light text-textContent text-center"
            >
              打通跨境电商业务全流程，闭环管理业务，实现业绩增长
            </div>
          </div>
          <div
            class="w-full grid grid-cols-2 gap-4 mt-8 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-10 lg:mt-16"
          >
            <div data-v-73a06f5c="" class="core-card items-start">
              <div data-v-73a06f5c="" class="img-container">
                <el-icon :size="50" color="#409efc"> <Share /></el-icon>
              </div>
              <p
                data-v-73a06f5c=""
                class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
              >
                进销存一体管理
              </p>
              <p
                data-v-73a06f5c=""
                class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
              >
                结合全球销量与货物运输周期等提供补货建议，提前安排采购或发货
              </p>
            </div>
            <div data-v-73a06f5c="" class="core-card items-start">
              <div data-v-73a06f5c="" class="img-container">
                <el-icon :size="50" color="#409efc"><Histogram /></el-icon>
              </div>
              <p
                data-v-73a06f5c=""
                class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
              >
                财务精准管理
              </p>
              <p
                data-v-73a06f5c=""
                class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
              >
                每日实时利润统计，订单级回款跟踪，自动提现，业财一体化管理
              </p>
            </div>
            <div data-v-73a06f5c="" class="core-card items-start">
              <div data-v-73a06f5c="" class="img-container">
                <el-icon :size="50" color="#409efc"><DocumentCopy /></el-icon>
              </div>
              <p
                data-v-73a06f5c=""
                class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
              >
                多维度数据报表
              </p>
              <p
                data-v-73a06f5c=""
                class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
              >
                一表汇总公司经营数据情况，快速洞察问题，调整业务策略
              </p>
            </div>
            <div data-v-73a06f5c="" class="core-card items-start">
              <div data-v-73a06f5c="" class="img-container">
                <el-icon :size="50" color="#409efc"><Memo /></el-icon>
              </div>
              <p
                data-v-73a06f5c=""
                class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
              >
                全周期广告管理
              </p>
              <p
                data-v-73a06f5c=""
                class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
              >
                支持以产品为中心进行广告“投前-投中-投后”管理，实现自动化投放与策略优化
              </p>
            </div>
            <div data-v-73a06f5c="" class="core-card items-start">
              <div data-v-73a06f5c="" class="img-container">
                <el-icon :size="50" color="#409efc"><Message /></el-icon>
              </div>
              <p
                data-v-73a06f5c=""
                class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
              >
                高效运营工具
              </p>
              <p
                data-v-73a06f5c=""
                class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
              >
                实时监控关键词排名、竞品监控、ChatGPT文案等工具，辅助运营决策
              </p>
            </div>
            <div data-v-73a06f5c="" class="core-card items-start">
              <div data-v-73a06f5c="" class="img-container">
                <el-icon :size="50" color="#409efc"><Stopwatch /></el-icon>
              </div>
              <p
                data-v-73a06f5c=""
                class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
              >
                售后客服管理
              </p>
              <p
                data-v-73a06f5c=""
                class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
              >
                自动分类汇总分析客户反馈，统一管理RMA品质问题、邮件信息等
              </p>
            </div>
            <div data-v-73a06f5c="" class="core-card items-start">
              <div data-v-73a06f5c="" class="img-container">
                <el-icon :size="50" color="#409efc"><Key /></el-icon>
              </div>
              <p
                data-v-73a06f5c=""
                class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
              >
                风险全维管理
              </p>
              <p
                data-v-73a06f5c=""
                class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
              >
                权限最小化管理，促销/库存/财务/广告等异常提前预警，自定义审批流，预防风险
              </p>
            </div>
            <div data-v-73a06f5c="" class="core-card items-start">
              <div data-v-73a06f5c="" class="img-container">
                <el-icon :size="50" color="#409efc"><Menu /></el-icon>
              </div>
              <p
                data-v-73a06f5c=""
                class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
              >
                手机端随时用
              </p>
              <p
                data-v-73a06f5c=""
                class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
              >
                支持APP看数据/调广告/审批单据/回站内信，微信每日自动推送业绩报告
              </p>
            </div>
          </div>
        </div>

        <div data-v-b7d2c1b8="" class="lx-home-module-container">
          <div data-v-b7d2c1b8="" class="lx-home-module">
            <div
              data-v-b7d2c1b8=""
              data-aos="fade-up"
              class="lx-home-module-item aos-init aos-animate"
            >
              <div data-v-47b03c32="" data-v-b7d2c1b8="" class="lx-list">
                <header data-v-47b03c32="" class="lx-list-header">
                  进销存一体管理
                </header>
                <p data-v-47b03c32="" class="lx-list-description">
                  <span data-v-b7d2c1b8="" data-v-47b03c32=""
                    >结合全球销量与货物运输周期等提供补货建议，提前安排采购或发货</span
                  >
                </p>
                <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                  <img
                    data-v-47b03c32=""
                    width="100%"
                    src="@/assets/343ddf442b0a79ab03a900489d23504a.png"
                    alt="进销存一体管理"
                  />
                </div>
                <ul data-v-47b03c32="" class="lx-list-container">
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    采购业务场景全覆盖
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      支持1688/线下下单/委外加工/退换货等采购方式，可按产品/供应商/采购员等维度管理采购
                    </p>
                  </li>
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    物流全链条追踪管理
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      全链条跟踪物流轨迹，对比物流价格，考核物流商绩效，自动物流对账，优化物流成本
                    </p>
                  </li>
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    智能补货建议
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      自动追踪全球销量与库存情况，多物流渠道计算，提供连续补货计划，提前做好资金规划
                    </p>
                  </li>
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    一站式管库存
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      集成300+海外仓/250+物流商，支持一件代发与库存对比，统一管理本地仓/海外仓/FBA仓库存和在途库存
                    </p>
                  </li>
                </ul>
                <div
                  data-v-47b03c32=""
                  class="space-x-4 flex"
                  style="font-size: 0px"
                >
                  <el-button type="primary" size="large" @click="tryout()"
                    >立即试用<el-icon class="el-icon--right"
                      ><ArrowRight /></el-icon
                  ></el-button>
                </div>
                <div data-v-47b03c32="" class="bg-grey" style="display: none">
                  <div data-v-47b03c32="" class="video md:block">
                    <div data-v-47b03c32="" id="lx-player-1">
                      <i
                        data-v-47b03c32=""
                        class="close-video iconfont icon-close"
                      ></i>
                    </div>
                    <div
                      data-v-47b03c32=""
                      class="video-text flex justify-center items-center h-24"
                    >
                      <p
                        data-v-47b03c32=""
                        class="text-2xl font-medium inline-block mr-4"
                      >
                        百宸ERP，助力实现精细管理和业务增长
                      </p>
                      <button
                        data-v-47b03c32=""
                        type="button"
                        class="el-button h-10 el-button--primary el-button--medium"
                      >
                        <!----><!----><span>立即体验功能</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div data-v-b7d2c1b8="" class="img-container">
                <img
                  data-v-b7d2c1b8=""
                  width="100%"
                  src="@/assets/343ddf442b0a79ab03a900489d23504a.png"
                  alt="百宸ERP官网-跨境电商ERP软件平台_亚马逊ERP管理系统"
                />
              </div>
            </div>
          </div>
          <div data-v-b7d2c1b8="" class="lx-home-module">
            <div
              data-v-b7d2c1b8=""
              data-aos="fade-up"
              class="lx-home-module-item aos-init"
            >
              <div data-v-47b03c32="" data-v-b7d2c1b8="" class="lx-list">
                <header data-v-47b03c32="" class="lx-list-header">
                  财务精准管理
                </header>
                <p data-v-47b03c32="" class="lx-list-description">
                  <span data-v-b7d2c1b8="" data-v-47b03c32=""
                    >每日实时利润统计，订单级回款跟踪，自动提现，业财一体化管理</span
                  >
                </p>
                <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                  <img
                    data-v-47b03c32=""
                    width="100%"
                    src="@/assets/4885c677d7dee76b7961b68adc181892.png"
                    alt="财务精准管理"
                  />
                </div>
                <ul data-v-47b03c32="" class="lx-list-container">
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    <p data-v-30e904fc="" class="lx-list-item-detail"></p>
                  </li>
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    实时看利润
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      精准核算利润，实时整合提供利润报表，反哺销售决策调整
                    </p>
                  </li>
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    成本严控制
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      业务成本一目了然，隐形费用自动识别，精准统计所有成本
                    </p>
                  </li>
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    资金流追踪
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      打通“下单-付款-发货-结算-回款”全链路，自动跟踪每一笔订单的回款
                    </p>
                  </li>
                </ul>
                <div
                  data-v-47b03c32=""
                  class="space-x-4 flex"
                  style="font-size: 0px"
                >
                  <el-button type="primary" size="large" @click="tryout()"
                    >立即试用<el-icon class="el-icon--right"
                      ><ArrowRight /></el-icon
                  ></el-button>
                  <!---->
                  <!---->
                </div>
                <div data-v-47b03c32="" class="bg-grey" style="display: none">
                  <div data-v-47b03c32="" class="video md:block">
                    <div data-v-47b03c32="" id="lx-player-9">
                      <i
                        data-v-47b03c32=""
                        class="close-video iconfont icon-close"
                      ></i>
                    </div>
                    <div
                      data-v-47b03c32=""
                      class="video-text flex justify-center items-center h-24"
                    >
                      <p
                        data-v-47b03c32=""
                        class="text-2xl font-medium inline-block mr-4"
                      >
                        百宸ERP，助力实现精细管理和业务增长
                      </p>
                      <button
                        data-v-47b03c32=""
                        type="button"
                        class="el-button h-10 el-button--primary el-button--medium"
                      >
                        <!----><!----><span>立即体验功能</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div data-v-b7d2c1b8="" class="img-container">
                <img
                  data-v-b7d2c1b8=""
                  width="100%"
                  src="@/assets/4885c677d7dee76b7961b68adc181892.png"
                  alt="百宸ERP官网-跨境电商ERP软件平台_亚马逊ERP管理系统"
                />
              </div>
            </div>
          </div>
          <div data-v-b7d2c1b8="" class="lx-home-module">
            <div
              data-v-b7d2c1b8=""
              data-aos="fade-up"
              class="lx-home-module-item aos-init"
            >
              <div data-v-47b03c32="" data-v-b7d2c1b8="" class="lx-list">
                <header data-v-47b03c32="" class="lx-list-header">
                  多维度数据报表
                </header>
                <p data-v-47b03c32="" class="lx-list-description">
                  <span data-v-b7d2c1b8="" data-v-47b03c32=""
                    >一表汇总公司经营数据情况，快速洞察问题，调整业务策略</span
                  >
                </p>
                <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                  <img
                    data-v-47b03c32=""
                    width="100%"
                    src="@/assets/30f52d6afb998b2136bda6967d27caf9.png"
                    alt="多维度数据报表"
                  />
                </div>
                <ul data-v-47b03c32="" class="lx-list-container">
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    全局数据看板
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      首页看板总览全局，销量分析、广告表现等数据一目了然，便于了解核心数据快速决策
                    </p>
                  </li>
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    多维数据报表
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      自动提供“人、财、货”相关数据报表，随时了解人员、货物、财务表现，支撑业务决策
                    </p>
                  </li>
                  <li
                    data-v-30e904fc=""
                    data-v-b7d2c1b8=""
                    class="lx-list-item"
                    data-v-47b03c32=""
                  >
                    深度数据分析
                    <p data-v-30e904fc="" class="lx-list-item-detail">
                      支持自定义数据交叉分析，数据环比趋势分析与展示，且可下载按需分析数据
                    </p>
                  </li>
                </ul>
                <div
                  data-v-47b03c32=""
                  class="space-x-4 flex"
                  style="font-size: 0px"
                >
                  <el-button type="primary" size="large" @click="tryout()"
                    >立即试用<el-icon class="el-icon--right"
                      ><ArrowRight /></el-icon
                  ></el-button>
                  <!---->
                  <!---->
                </div>
                <div data-v-47b03c32="" class="bg-grey" style="display: none">
                  <div data-v-47b03c32="" class="video md:block">
                    <div data-v-47b03c32="" id="lx-player-11">
                      <i
                        data-v-47b03c32=""
                        class="close-video iconfont icon-close"
                      ></i>
                    </div>
                    <div
                      data-v-47b03c32=""
                      class="video-text flex justify-center items-center h-24"
                    >
                      <p
                        data-v-47b03c32=""
                        class="text-2xl font-medium inline-block mr-4"
                      >
                        百宸ERP，助力实现精细管理和业务增长
                      </p>
                      <button
                        data-v-47b03c32=""
                        type="button"
                        class="el-button h-10 el-button--primary el-button--medium"
                      >
                        <!----><!----><span>立即体验功能</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div data-v-b7d2c1b8="" class="img-container">
                <img
                  data-v-b7d2c1b8=""
                  width="100%"
                  src="@/assets/30f52d6afb998b2136bda6967d27caf9.png"
                  alt="百宸ERP官网-跨境电商ERP软件平台_亚马逊ERP管理系统"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="section-reason w-full max-w-7xl mx-auto text-center pt-20 lg:pt-30 lg:pb-40"
        >
          <div class="w-full px-15px mb-4 lg:px-0 lg:mb-0">
            <div
              data-v-57c9ab6d=""
              class="flex justify-center flex-col items-center lx-large-title aos-init aos-animate"
              data-aos="fade-up"
            >
              <h3
                data-v-57c9ab6d=""
                class="lx-title w-full"
                style="text-align: center"
              >
                为什么选择百宸
              </h3>
              <div
                data-v-57c9ab6d=""
                class="max-w-840px text-sm px-15px lg:text-lg lg:px-0 font-light text-textContent text-center"
              >
                选择百宸即选择了精细化管理的模式，全面赋能实现业务增长
              </div>
            </div>
          </div>
          <div class="mt-20 grid grid-cols-3 gap-x-25">
            <div data-v-5f115aee="" class="section-reason-card-item">
              <div
                data-v-5f115aee=""
                class="flex flex-col items-center py-6 px-2.5 lg:pt-6 lg:pb-9 lg:px-10"
              >
                <el-icon :size="50" color="#409efc"><Clock /></el-icon>
                <p data-v-5f115aee="" class="lx-reason-card-title">
                  全生态产品
                </p>
                <p
                  data-v-5f115aee=""
                  class="text-textPrimary font-normal text-sm mt-2 lg:text-lg lg:mt-2"
                >
                  贴合跨境卖家核心使用场景
                </p>
                <div data-v-5f115aee="" class="mt-6 mt-6">
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    SaaS软件，无需下载即可使用
                  </p>
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    对接支付、物流等系统，全链路闭环管理
                  </p>
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    功能版块全，包含进销存、财务、广告等
                  </p>
                </div>
              </div>
              <div data-v-5f115aee="" class="lx-reason-card-type">
                <p data-v-5f115aee="" class="lx-reason-tip">10+</p>
                <p data-v-5f115aee="" class="lx-reason-tip-description">
                  跨境产品模块
                </p>
              </div>
            </div>
            <div data-v-5f115aee="" class="section-reason-card-item">
              <div
                data-v-5f115aee=""
                class="flex flex-col items-center py-6 px-2.5 lg:pt-6 lg:pb-9 lg:px-10"
              >
                <el-icon :size="50" color="#409efc"><CircleCheck /></el-icon>
                <p data-v-5f115aee="" class="lx-reason-card-title">
                  全方位保障
                </p>
                <p
                  data-v-5f115aee=""
                  class="text-textPrimary font-normal text-sm mt-2 lg:text-lg lg:mt-2"
                >
                  深度开发能力与数据安全护航
                </p>
                <div data-v-5f115aee="" class="mt-6 mt-6">
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    自研亿级任务调度引擎，运行快速
                  </p>
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    定期检测漏洞，签署、严守保密协议
                  </p>
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    亚马逊SPN服务商，国家高新技术企业认定
                  </p>
                </div>
              </div>
              <div data-v-5f115aee="" class="lx-reason-card-type">
                <p data-v-5f115aee="" class="lx-reason-tip">0.1s</p>
                <p data-v-5f115aee="" class="lx-reason-tip-description">
                  快速响应
                </p>
              </div>
            </div>
            <div data-v-5f115aee="" class="section-reason-card-item">
              <div
                data-v-5f115aee=""
                class="flex flex-col items-center py-6 px-2.5 lg:pt-6 lg:pb-9 lg:px-10"
              >
                <el-icon :size="50" color="#409efc"><User /></el-icon>
                <p data-v-5f115aee="" class="lx-reason-card-title">
                  全周期服务
                </p>
                <p
                  data-v-5f115aee=""
                  class="text-textPrimary font-normal text-sm mt-2 lg:text-lg lg:mt-2"
                >
                  全程专业服务，满足卖家需求
                </p>
                <div data-v-5f115aee="" class="mt-6 mt-6">
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    软件顾问、实施、售后全流程服务
                  </p>
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    一体化专业服务，深度赋能客户
                  </p>
                  <p
                    data-v-5f115aee=""
                    class="text-textPrimary font-light mb-1 lg:text-sm lg:mb-2 text-xs"
                  >
                    为软件使用负责，持续交付新功能
                  </p>
                </div>
              </div>
              <div data-v-5f115aee="" class="lx-reason-card-type">
                <p data-v-5f115aee="" class="lx-reason-tip">20+</p>
                <p data-v-5f115aee="" class="lx-reason-tip-description">
                  服务中心
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>
<script setup>
import { ElIcon } from "element-plus";
import "element-plus/es/components/icon/style/css";
// 局部导入图标组件

import {
  Share,
  Menu,
  Message,
  Stopwatch,
  Key,
  DocumentCopy,
  Memo,
  Histogram,
  ArrowRight,
  Clock,
  CircleCheck,
  User,
} from "@element-plus/icons-vue";
</script>
<script>
export default {
  data() {
    return {
      images: [
        { src: "/1e0bcd5e813baa9e03b70b3670440289.png", width: "96px" },
        { src: "/2bdc3db1c8e75607624b73da92ed3c1a.png", width: "96px" },
        { src: "/2cd4f0ce47aee71014763de201b28f56.png", width: "96px" },
        { src: "/7a1b07c94c2a335484791e422c165978.png", width: "96px" },
        { src: "/7eadfbc24ac5e535a54542abddab2b3b.png", width: "96px" },
        { src: "/8a4d3afac701c5937d6c47612e3f1428.png", width: "96px" },
        { src: "/36b83e267fad878468962c097fbbcf9a.png", width: "96px" },
        { src: "/404f58d67d01c6bbef15159cd834f365.png", width: "52.8px" },
        { src: "/445ec5bc764388ae22a0e7ae0f2935bb.png", width: "96px" },
        { src: "/694d3de02a6b1465c5bf179ccc4629f5.png", width: "52.8px" },
        { src: "/777f9c09a3cd6075f5acf37a3eaf455f.png", width: "96px" },
        { src: "/4855e281b97935a54cded33b3ae69309.png", width: "96px" },
        { src: "/57532a32cd73dd27653f265de5d9b554.png", width: "74.39px" },
        { src: "/63883de30bc47f77d2b2111a9f17cff0.png", width: "96px" },
        { src: "/89187b19ae0513a9a732029b2c8b4a51.png", width: "96px" },
        { src: "/185031eeb75240146b47e094d490a80c.png", width: "96px" },
        { src: "/661573a0962cd7d2fc822d9c1757d217.png", width: "96px" },
        { src: "/a7433db37bf5ef24c773b7d4b320b157.png", width: "96px" },
        { src: "/b7ff4aeb99678bd845bafa708ffd5926.png", width: "96px" },
        { src: "/c480a5966bc420e18cf4c1dc01b065dd.png", width: "96px" },
        { src: "/d935570d24e2c0da45e38b65fd3544fb.png", width: "96px" },
        { src: "/eab273e98152635430c8e3fc5f96b304.png", width: "79.19px" },
        { src: "/f798f678600407b4d4277f12259a3cd8.png", width: "96px" },
        { src: "/fa10b142c9e082fa7ea0d6d7ed7b12cf.png", width: "96px" },
      ],
    };
  },

  mounted() {
    this.startAnimation();
  },
  methods: {
    startAnimation() {
      const carousel = this.$refs.carousel;
      const firstImage = carousel.children[0];
      const imageWidth = firstImage.clientWidth;

      // 复制图片以实现无缝循环
      for (let i = 0; i < this.images.length; i++) {
        const clone = carousel.children[i].cloneNode(true);
        carousel.appendChild(clone);
      }

      // 设置动画效果
      carousel.style.animation = `scroll ${
        this.images.length * 0.6
      }s linear infinite`;

      // 动态设置关键帧
      const keyframes = `
        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(-${imageWidth * this.images.length}px); }
        }
      `;
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = keyframes;
      document.head.appendChild(styleSheet);
    },
    tryout() {
      window.open("http://erp.insmeta.net/");
    },
  },

  name: "Home",
  // components: {
  //   HelloWorld
  // }
};
</script>
<style scoped>
.en-main[data-v-109a143a],
.footer[data-v-109a143a],
.main[data-v-109a143a] {
  width: 100%;
}
main {
  display: block;
  unicode-bidi: isolate;
}

@media (min-width: 1024px) {
  .lx-banner-container[data-v-a32e13b0] {
    min-height: 33.75rem;
    padding-left: 0;
    padding-right: 0;
  }
}
.lx-banner-container[data-v-a32e13b0] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (min-width: 1024px) {
  .lg\:mt-10 {
    margin-top: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .lg\:items-center {
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.lx-content-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  width: 100%;
}
@media (min-width: 1024px) {
  .lg\:text-left {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }
}

.mb-4 {
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .lx-banner-title[data-v-a32e13b0] {
    font-size: 2.25rem;
    line-height: 3.125rem;
  }
}
.lx-banner-title[data-v-a32e13b0] {
  font-weight: 600;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(58, 64, 87, var(--tw-text-opacity));
}
.lx-banner-container .hight-light[data-v-a32e13b0] {
  --tw-text-opacity: 1;
  color: rgba(37, 84, 254, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .lg\:text-lg {
    font-size: 1.125rem;
  }
}
.text-textContent {
  --tw-text-opacity: 1;
  color: rgba(92, 94, 102, var(--tw-text-opacity));
}
.mb-8 {
  margin-bottom: 2rem;
}
.mt-\[6px\] {
  margin-top: 6px;
}
.text-xs {
  font-size: 0.75rem;
}
.font-light {
  font-weight: 300;
}
.lx-banner-container .source-tip[data-v-a32e13b0] {
  margin-top: 64px;
  color: #a2aeca;
  font-size: 14px;
  padding-bottom: 50px;
}
@media (min-width: 1024px) {
  .lx-banner-container .banner-img[data-v-a32e13b0] {
    border-radius: 1.5rem;
    margin-left: 1.25rem;
    min-width: 50rem;
    width: 50rem;
  }
}
.lx-banner-container .banner-img[data-v-a32e13b0] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1rem;
  border-radius: 1.5rem;
  max-width: 50rem;
  position: relative;
  width: 100%;
}
.lx-banner-container .video-con[data-v-a32e13b0] {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  position: fixed;
  top: 24999.75rem;
  left: 0;
  width: 100%;
  z-index: 99;
}
.lx-banner-container .close-video[data-v-a32e13b0],
.lx-banner-container .play-con[data-v-a32e13b0] {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .lg\:-mt-13 {
    margin-top: -3.25rem;
  }
}
.w-full {
  width: 100%;
}
.max-w-7xl {
  max-width: 80rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.box[data-v-13cd1c6f] {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin-top: 0.75rem;
}

.carousel-container {
  overflow: hidden;
  width: 100%; /* 容器宽度，具体值根据需求设置 */
}

.carousel {
  display: flex;
  white-space: nowrap;
}

.carousel img {
  height: 48px; /* 图片宽度，具体值根据需求设置 */
  flex-shrink: 0;
  margin: 0 20px;
}
@media (min-width: 1024px) {
  .lg\:my-30 {
    margin-top: 2.5rem;
    margin-bottom: 7.5rem;
  }
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
@media (min-width: 1024px) {
  .lx-middle-title .lx-title[data-v-57c9ab6d] {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
}
@media (min-width: 1024px) {
  .lx-title[data-v-57c9ab6d] {
    font-size: 2.25rem;
    line-height: 50px;
    margin-bottom: 0.5rem;
  }
}
.lx-title[data-v-57c9ab6d] {
  font-size: 2.25rem;
  color: #1a2035;
  font-weight: 500;
  line-height: 1.75rem;
  margin-bottom: 1.25rem;
  max-width: 840px;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
  letter-spacing: 1px;
}
.max-w-840px {
  max-width: 840px;
}
@media (min-width: 1024px) {
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .lg\:gap-y-10 {
    -webkit-row-gap: 2.5rem;
    -moz-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:gap-x-8 {
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
}
@media (min-width: 1024px) {
  .lg\:mt-16 {
    margin-top: 4rem;
  }
}
.grid {
  display: grid;
}
@media (min-width: 1024px) {
  .core-card[data-v-73a06f5c] {
    padding: 1.5rem 1.5rem 2rem;
  }
}
.core-card[data-v-73a06f5c] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0.625rem;
  box-shadow: 0 0 10px rgba(180, 189, 221, 0.08),
    0 6px 14px hsla(0, 0%, 100%, 0.3), 0 12px 20px rgba(180, 189, 221, 0.2);
}
.items-start {
  align-items: flex-start;
}
iframe,
img,
svg,
video {
  display: block;
  vertical-align: middle;
}
@media (min-width: 1024px) {
  .lg\:mt-4 {
    margin-top: 1rem;
  }
}
@media (min-width: 1024px) {
  .lg\:text-22px {
    font-size: 22px;
    line-height: 1;
  }
}
.text-textTitle {
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
}
.font-medium {
  font-weight: 500;
}
@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }
}
.text-textSubtitle {
  --tw-text-opacity: 1;
  color: rgba(58, 64, 87, var(--tw-text-opacity));
}
.text-left {
  text-align: left;
}
.mt-3\.5 {
  margin-top: 0.875rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.font-normal {
  font-weight: 400;
}
.lx-home-module-container[data-v-b7d2c1b8] {
  display: flex;
  flex-direction: column;
}
.lx-home-module-container .lx-home-module[data-v-b7d2c1b8]:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 249, 255, var(--tw-bg-opacity));
}
@media (min-width: 1024px) {
  .lx-home-module-container .lx-home-module[data-v-b7d2c1b8] {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}
.lx-home-module-container
  .lx-home-module:nth-child(odd)
  .lx-home-module-item[data-v-b7d2c1b8] {
  flex-direction: row-reverse;
}
@media (min-width: 1024px) {
  .lx-home-module-container
    .lx-home-module
    .lx-home-module-item[data-v-b7d2c1b8] {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
}
.lx-home-module-container
  .lx-home-module
  .lx-home-module-item[data-v-b7d2c1b8] {
  display: flex;

  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1024px) {
  .lx-home-module-container
    .lx-home-module
    .lx-home-module-item
    .lx-list[data-v-b7d2c1b8] {
    width: 30rem;
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .lx-list .lx-list-header[data-v-47b03c32] {
    font-size: 2.25rem;
  }
}
.lx-list .lx-list-header[data-v-47b03c32] {
  font-weight: 500;
  font-size: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
  letter-spacing: 1px;
}
@media (min-width: 1024px) {
  .lx-list .lx-list-description[data-v-47b03c32] {
    font-size: 1rem;
  }
}
.lx-list .lx-list-description[data-v-47b03c32] {
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
  letter-spacing: 1px;
}
dd,
dl,
h2,
h3,
p {
  margin: 0;
}
@media (min-width: 1024px) {
  .lg\:hidden {
    display: none;
  }
}
.mt-4 {
  margin-top: 1rem;
}
img,
video {
  max-width: 85%;
  height: auto;
}
@media (min-width: 1024px) {
  .lx-list .lx-list-container[data-v-47b03c32] {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.lx-list .lx-list-container[data-v-47b03c32] {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .lx-list-item[data-v-30e904fc] {
    font-size: 1.125rem;
  }
}
.lx-list-item[data-v-30e904fc] {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75rem;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .en-main .lx-list-item .lx-list-item.no-description[data-v-30e904fc],
  .lx-list-item .en-main .lx-list-item.no-description[data-v-30e904fc],
  .lx-list-item .lx-list-item-detail[data-v-30e904fc] {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}
.en-main .lx-list-item .lx-list-item.no-description[data-v-30e904fc],
.lx-list-item .en-main .lx-list-item.no-description[data-v-30e904fc],
.lx-list-item .lx-list-item-detail[data-v-30e904fc] {
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 22px;
  --tw-text-opacity: 1;
  color: rgba(92, 94, 102, var(--tw-text-opacity));
}
.lx-list-item[data-v-30e904fc]:before {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 84, 254, var(--tw-bg-opacity));
  border-radius: 50%;
  position: absolute;
  left: -1.25rem;
  top: 11px;
  content: "";
  width: 6px;
  height: 6px;
  box-shadow: 0 0 0 5px rgba(37, 84, 254, 0.1);
}
.lx-home-module-container .lx-home-module[data-v-b7d2c1b8]:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
@media (min-width: 1024px) {
  .lx-home-module-container
    .lx-home-module
    .lx-home-module-item
    .img-container[data-v-b7d2c1b8] {
    display: block;
    width: 40rem;
  }
}
@media (min-width: 1024px) {
  .lx-list[data-v-47b03c32] {
    margin-top: 0;
    width: 30rem;
  }
}
@media (min-width: 1024px) {
  .lg\:pb-40 {
    padding-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .lg\:pt-30 {
    padding-top: 7.5rem;
  }
}
.text-center {
  text-align: center;
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.gap-x-25 {
  -webkit-column-gap: 6.25rem;
  -moz-column-gap: 6.25rem;
  grid-column-gap: 6.25rem;
  column-gap: 6.25rem;
}
.mt-20 {
  margin-top: 5rem;
}
.section-reason-card-item[data-v-5f115aee] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 16px 0 rgba(180, 189, 221, 0.1),
    0 6px 14px 0 hsla(0, 0%, 100%, 0.3), 0 16px 20px 0 rgba(180, 189, 221, 0.25);
  transition: all 0.4s ease-in-out;
}
@media (min-width: 1024px) {
  .lg\:pb-9 {
    padding-bottom: 2.25rem;
  }
}
@media (min-width: 1024px) {
  .lg\:pt-6 {
    padding-top: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.section-reason-card-item .lx-reason-card-title[data-v-5f115aee] {
  font-weight: 500;
  font-size: 1.875rem;
  margin-top: 1rem;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .lg\:mt-2 {
    margin-top: 0.5rem;
  }
}
.text-textPrimary {
  --tw-text-opacity: 1;
  color: rgba(58, 64, 87, var(--tw-text-opacity));
}
.mt-6 {
  margin-top: 1.5rem;
}
@media (min-width: 1024px) {
  .lg\:mb-2 {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:text-sm {
    font-size: 0.875rem;
  }
}
.section-reason-card-item .lx-reason-card-type[data-v-5f115aee] {
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 100%;
  border-radius: 0 0 16px 16px;
  background-image: url(https://static.distributetop.com/office-site-new/office-site-upload/img/reason-card-bg.1c4fd50.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.section-reason-card-item .lx-reason-card-type .lx-reason-tip[data-v-5f115aee] {
  font-family: Helvetica Neue;
  font-size: 32px;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 2.5rem;
}
.section-reason-card-item[data-v-5f115aee]:hover {
  transform: translateY(-8px);
  box-shadow: 10px 10px 38px 0 hsla(0, 0%, 100%, 0.3),
    0 0 14px 0 rgba(202, 210, 238, 0.2),
    28px 36px 50px 0 rgba(180, 189, 221, 0.46);
}
</style>
