<template>
  <div class="product">
    <div class="lx-finance-module" data-v-d2b0f33a="" data-v-109a143a="">
      <div data-v-a32e13b0="" data-v-d2b0f33a="" class="lx-banner-container">
        <div
          data-v-a32e13b0=""
          class="lx-content-container flex-col items-center mt-8 mb-10 lg:flex-row lg:justify-between lg:items-center lg:mt-10"
        >
          <section
            data-v-a32e13b0=""
            class="text-center mb-4 lg:text-left lg:mb-0"
          >
            <!---->
            <h3 data-v-a32e13b0="" class="lx-banner-title">
              <span data-v-a32e13b0="" class="">业财一体化管理</span>
            </h3>
            <!---->
            <p
              data-v-a32e13b0=""
              class="text-xs lg:text-lg font-light text-textContent mt-[6px] mb-8"
            >
              深度整合跨境电商各个关键环节的多维数据，洞察业务数据变化，精准高效决策
            </p>
            <el-button size="large" type="primary" @click="tryout()"
              >免费试用</el-button
            >
            <!---->
            <p data-v-a32e13b0="" class="source-tip" style="display: none">
              来源：艾瑞咨询《2024年中国跨境电商软件服务行业报告》
            </p>
          </section>
          <section data-v-a32e13b0="" class="banner-img">
            <video
              data-v-a32e13b0=""
              id="videoPlay"
              src="@/assets/ca42c10505cb1b22ff965d1b666330f1.mp4"
              webkit-playsinline="true"
              playsinline="true"
              x5-playsinline="true"
              x5-video-player-fullscreen=""
              width="100%"
              autoplay="autoplay"
              loop="loop"
              muted="muted"
              disablepictureinpicture=""
              preload=""
              class="banner-main-img banner-video"
            ></video>
            <!---->
            <!---->
            <!---->
          </section>
        </div>
        <div data-v-a32e13b0="" class="video-con">
          <div data-v-a32e13b0="" id="lx-player">
            <i data-v-a32e13b0="" class="close-video iconfont icon-close"></i>
          </div>
        </div>
      </div>
      <div data-v-d2b0f33a="" class="blank-area"></div>
      <div class="lx-module-section nav-container" data-v-d2b0f33a="">
        <div class="nav-content" data-v-d2b0f33a="">
          <ul
            class="module-nav"
            :style="{ width: navWidth + 'px' }"
            data-v-d2b0f33a=""
          >
            <li
              v-for="(item, index) in items"
              :key="index"
              :class="['module-nav-item']"
              v-smooth-scroll:targetId="'targetElementId'"
              data-v-d2b0f33a=""
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div
          data-v-d2b0f33a=""
          class="lg:hidden sticky top-16 z-2 w-full px-[15px] bg-colorBg"
        ></div>
        <div class="module" data-v-d2b0f33a="">
          <div
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0"
            data-v-d2b0f33a=""
          >
            <div class="lx-list" data-v-47b03c32="" data-v-d2b0f33a="">
              <header class="lx-list-header" data-v-47b03c32="">
                亚马逊结算中心
              </header>
              <p class="lx-list-description" data-v-47b03c32="">
                <span data-v-47b03c32="" data-v-d2b0f33a=""
                  >接入亚马逊财务接口，实时同步财务数据，随时了解账单变动情况</span
                >
              </p>
              <div class="mt-4 block lg:hidden" data-v-47b03c32="">
                <img
                  width="100%"
                  src="@/assets/4885c677d7dee76b7961b68adc181892.png"
                  alt="亚马逊结算中心"
                  data-v-47b03c32=""
                />
              </div>
              <ul class="lx-list-container" data-v-47b03c32="">
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  订单级别回款监控
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    支持标记订单结算、回款等状态，实时掌握应收账款余额情况
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  快速了解回款情况
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    提供可视化看板，快速查看回款，为发货未结算数据提供基础
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  支持凸显异常数据
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    支持与Summary月度账单对账，清晰可见平台数据差异，降低财务对账难度
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  实时获取利润数据
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    实时获取利润数据，自动形成可视化看板，快速了解结算情况
                  </p>
                </li>
              </ul>
              <div
                class="space-x-4 flex"
                style="font-size: 0"
                data-v-47b03c32=""
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div class="bg-grey" style="display: none" data-v-47b03c32="">
                <div class="video md:block" data-v-47b03c32="">
                  <div id="lx-player-63" data-v-47b03c32="">
                    <i
                      class="close-video iconfont icon-close"
                      data-v-47b03c32=""
                    ></i>
                  </div>
                  <div
                    class="video-text flex justify-center items-center h-24"
                    data-v-47b03c32=""
                  >
                    <p
                      class="text-2xl font-medium inline-block mr-4"
                      data-v-47b03c32=""
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                      data-v-47b03c32=""
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full hidden lg:block lg:w-160" data-v-d2b0f33a="">
              <img
                width="100%"
                src="@/assets/4885c677d7dee76b7961b68adc181892.png"
                alt="跨境电商精细化财务管理_业财一体化管理系统-百宸ERP"
                data-v-d2b0f33a=""
              />
            </div>
          </div>
        </div>
        <div class="module" data-v-d2b0f33a="">
          <div
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0"
            data-v-d2b0f33a=""
          >
            <div class="lx-list" data-v-47b03c32="" data-v-d2b0f33a="">
              <header class="lx-list-header" data-v-47b03c32="">
                实时利润统计
              </header>
              <p class="lx-list-description" data-v-47b03c32="">
                <span data-v-47b03c32="" data-v-d2b0f33a=""
                  >利润数据每小时更新，把握业务盈利情况，及时调整策略</span
                >
              </p>
              <div class="mt-4 block lg:hidden" data-v-47b03c32="">
                <img
                  width="100%"
                  src="@/assets/19525b82e64fa9fae991c4533b2a230f.png"
                  alt="实时利润统计"
                  data-v-47b03c32=""
                />
              </div>
              <ul class="lx-list-container" data-v-47b03c32="">
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  即时查看平台利润
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    即时获取亚马逊结算中心账单数据，查看小时级别的利润数据
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  支持当日利润分析
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    支持从商品、店铺维度按日期查看利润数据，快速掌握当日利润情况
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  欧洲站费用自动分摊
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    欧洲站费用区分站点显示，广告费用可分摊到商品上
                  </p>
                </li>
              </ul>
              <div
                class="space-x-4 flex"
                style="font-size: 0"
                data-v-47b03c32=""
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div class="bg-grey" style="display: none" data-v-47b03c32="">
                <div class="video md:block" data-v-47b03c32="">
                  <div id="lx-player-61" data-v-47b03c32="">
                    <i
                      class="close-video iconfont icon-close"
                      data-v-47b03c32=""
                    ></i>
                  </div>
                  <div
                    class="video-text flex justify-center items-center h-24"
                    data-v-47b03c32=""
                  >
                    <p
                      class="text-2xl font-medium inline-block mr-4"
                      data-v-47b03c32=""
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                      data-v-47b03c32=""
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full hidden lg:block lg:w-160" data-v-d2b0f33a="">
              <img
                width="100%"
                src="@/assets/19525b82e64fa9fae991c4533b2a230f.png"
                alt="跨境电商精细化财务管理_业财一体化管理系统-百宸ERP"
                data-v-d2b0f33a=""
              />
            </div>
          </div>
        </div>
        <div class="module" data-v-d2b0f33a="">
          <div
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0"
            data-v-d2b0f33a=""
          >
            <div class="lx-list" data-v-47b03c32="" data-v-d2b0f33a="">
              <header class="lx-list-header" data-v-47b03c32="">
                业绩报表
              </header>
              <p class="lx-list-description" data-v-47b03c32="">
                <span data-v-47b03c32="" data-v-d2b0f33a=""
                  >自动抓取整合业绩指标，业绩趋势一表尽览</span
                >
              </p>
              <div class="mt-4 block lg:hidden" data-v-47b03c32="">
                <img
                  width="100%"
                  src="@/assets/47f88313d6ad57bdd05b594a2e02b137.png"
                  alt="业绩报表"
                  data-v-47b03c32=""
                />
              </div>
              <ul class="lx-list-container" data-v-47b03c32="">
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  人员业绩高效核算
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    负责人、开发人双维度业绩统计，自动计算毛利润和毛利率
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  业绩统计维度广
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    支持自定义标签，统计不同Listing、新老品类的利润表现等
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  趋势图直观展示变化
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    提供不同人员每月趋势图展示，直观清晰查看绩效变动
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  经营结果自动统计
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    自动抓取整合销量、销售额、退款等指标，及时统计结果
                  </p>
                </li>
              </ul>
              <div
                class="space-x-4 flex"
                style="font-size: 0"
                data-v-47b03c32=""
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div class="bg-grey" style="display: none" data-v-47b03c32="">
                <div class="video md:block" data-v-47b03c32="">
                  <div id="lx-player-2" data-v-47b03c32="">
                    <i
                      class="close-video iconfont icon-close"
                      data-v-47b03c32=""
                    ></i>
                  </div>
                  <div
                    class="video-text flex justify-center items-center h-24"
                    data-v-47b03c32=""
                  >
                    <p
                      class="text-2xl font-medium inline-block mr-4"
                      data-v-47b03c32=""
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                      data-v-47b03c32=""
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full hidden lg:block lg:w-160" data-v-d2b0f33a="">
              <img
                width="100%"
                src="@/assets/47f88313d6ad57bdd05b594a2e02b137.png"
                alt="跨境电商精细化财务管理_业财一体化管理系统-百宸ERP"
                data-v-d2b0f33a=""
              />
            </div>
          </div>
        </div>
        <div class="module" data-v-d2b0f33a="">
          <div
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0"
            data-v-d2b0f33a=""
          >
            <div class="lx-list" data-v-47b03c32="" data-v-d2b0f33a="">
              <header class="lx-list-header" data-v-47b03c32="">
                每日利润报表
              </header>
              <p class="lx-list-description" data-v-47b03c32="">
                <span data-v-47b03c32="" data-v-d2b0f33a=""
                  >整合海量数据，精准核算利润，支撑业务有序运转</span
                >
              </p>
              <div class="mt-4 block lg:hidden" data-v-47b03c32="">
                <img
                  width="100%"
                  src="@/assets/6372dc47caf0dd58540d78007a22612a.png"
                  alt="每日利润报表"
                  data-v-47b03c32=""
                />
              </div>
              <ul class="lx-list-container" data-v-47b03c32="">
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  数据来源广、精准度高

                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    整合10+原表，提供50+分析维度，精准度达上市公司报表级别
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  全局批次核算成本
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    成本计算支持全局批次核算，提供多种财务合规计价模型统计
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  货损情况可差异分摊

                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    支持差异分摊，清晰处理退货、库存调整、移除、差异等货损
                  </p>
                </li>
                <li
                  class="lx-list-item"
                  data-v-30e904fc=""
                  data-v-d2b0f33a=""
                  data-v-47b03c32=""
                >
                  财务数据按月封存
                  <p class="lx-list-item-detail" data-v-30e904fc="">
                    可进行结账与反结账操作，数据按月封存，方便财务做账核账
                  </p>
                </li>
              </ul>
              <div
                class="space-x-4 flex"
                style="font-size: 0"
                data-v-47b03c32=""
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div class="bg-grey" style="display: none" data-v-47b03c32="">
                <div class="video md:block" data-v-47b03c32="">
                  <div id="lx-player-1" data-v-47b03c32="">
                    <i
                      class="close-video iconfont icon-close"
                      data-v-47b03c32=""
                    ></i>
                  </div>
                  <div
                    class="video-text flex justify-center items-center h-24"
                    data-v-47b03c32=""
                  >
                    <p
                      class="text-2xl font-medium inline-block mr-4"
                      data-v-47b03c32=""
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                      data-v-47b03c32=""
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full hidden lg:block lg:w-160" data-v-d2b0f33a="">
              <img
                width="100%"
                src="@/assets/6372dc47caf0dd58540d78007a22612a.png"
                alt="跨境电商精细化财务管理_业财一体化管理系统-百宸ERP"
                data-v-d2b0f33a=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="py-12 px-15px lg:py-30 lg:px-0 bg-colorBg" data-v-d2b0f33a="">
        <div
          class="flex flex-col justify-between items-center max-w-7xl mx-auto lg:flex-row lg:space-x-10"
          data-v-d2b0f33a=""
        >
          <div class="text-center lg:text-left">
            <div
              class="text-xl inline-block lg:text-4xl lg:block !lg:leading-[54px] font-medium text-colorPrimary-100"
            >
              500000+
            </div>
            <div
              class="text-xl inline-block lg:text-4xl lg:block font-medium text-textTitle"
            >
              跨境企业的共同选择
            </div>
            <div
              class="text-sm mt-1 text-textContent font-light lg:text-base lg:mt-3 lg:text-textTitle lg:font-normal"
            >
              跨境电商上市企业和亿级规模卖家数量行业领先
            </div>
          </div>
          <div class="product-rumor" data-v-281394a7="">
            <div class="title" data-v-281394a7="">重新定义SaaS ERP</div>
            <div
              class="mt-4 text-sm font-light text-textContent lg:text-base lg:font-normal"
              data-v-281394a7=""
            >
              做一个好用且易用的ERP，让跨境电商更简单
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowRight } from "@element-plus/icons-vue";
import { ref, onMounted, onUnmounted } from "vue";

// 初始化宽度为最小值1280px
const items = ["亚马逊结算中心", "实时利润统计", "业绩报表", "每日利润报表"];
const navWidth = ref(1280);
let lastScrollTop = 0;
let reachedMaxWidth = false; // 新增变量，追踪是否达到过最大宽度

// 定义最大和最小宽度
const maxWidth = 1920;
const minWidth = 1280;

// 滚动事件处理函数
const handleScroll = () => {
  const st = window.pageYOffset || document.documentElement.scrollTop;

  // 如果已经达到了最大宽度
  if (reachedMaxWidth) {
    // 向上滚动时，从最大宽度开始减小
    navWidth.value = Math.max(minWidth, navWidth.value - (lastScrollTop - st));
  } else {
    // 向下滚动，增加宽度，但不超过最大值
    navWidth.value = Math.min(maxWidth, navWidth.value + (st - lastScrollTop));

    // 记录是否达到过最大宽度
    if (navWidth.value >= maxWidth) {
      reachedMaxWidth = true;
    }
  }

  lastScrollTop = st;
};

// 在组件挂载后添加滚动事件监听
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

// 在组件卸载前移除滚动事件监听
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

const tryout = () => {
  window.open("http://erp.insmeta.net/");
};
</script>
<style scoped>
@media (min-width: 1024px) {
  .lx-banner-container[data-v-a32e13b0] {
    min-height: 33.75rem;
    padding-left: 0;
    padding-right: 0;
  }
}
.lx-banner-container[data-v-a32e13b0] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (min-width: 1024px) {
  .lg\:mt-10 {
    margin-top: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .lg\:items-center {
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.lx-content-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  width: 100%;
}
@media (min-width: 1024px) {
  .lg\:text-left {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .lx-banner-title[data-v-a32e13b0] {
    font-size: 2.25rem;
    line-height: 3.125rem;
  }
}
.lx-banner-title[data-v-a32e13b0] {
  font-weight: 600;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(58, 64, 87, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .lg\:text-lg {
    font-size: 1.125rem;
  }
}
.text-textContent {
  --tw-text-opacity: 1;
  color: rgba(92, 94, 102, var(--tw-text-opacity));
}
.mb-8 {
  margin-bottom: 2rem;
}
.mt-\[6px\] {
  margin-top: 6px;
}
.font-light {
  font-weight: 300;
}

.lx-banner-container .source-tip[data-v-a32e13b0] {
  margin-top: 64px;
  color: #a2aeca;
  font-size: 14px;
  padding-bottom: 50px;
}

@media (min-width: 1024px) {
  .lx-banner-container .banner-img[data-v-a32e13b0] {
    border-radius: 1.5rem;
    margin-left: 1.25rem;
    min-width: 50rem;
    width: 50rem;
  }
}
.lx-banner-container .banner-img[data-v-a32e13b0] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1rem;
  border-radius: 1.5rem;
  max-width: 50rem;
  position: relative;
  width: 100%;
}
.lx-banner-container .banner-img .banner-video[data-v-a32e13b0] {
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: unset !important;
}
@media (min-width: 1024px) {
  .lx-banner-container .banner-img .banner-main-img[data-v-a32e13b0] {
    border-radius: 1.5rem;
  }
}
.blank-area[data-v-d2b0f33a] {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 249, 255, var(--tw-bg-opacity));
  width: 100%;
}
@media (min-width: 1024px) {
  .blank-area[data-v-d2b0f33a] {
    height: 5rem;
  }
}
.nav-container[data-v-d2b0f33a] {
  padding-top: 0;
}
.lx-module-section[data-v-d2b0f33a] {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .nav-content[data-v-d2b0f33a] {
    display: flex;
    top: 0;
  }
}
.nav-content[data-v-d2b0f33a] {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 249, 255, var(--tw-bg-opacity));
  align-items: center;
  justify-content: center;
  padding-top: 0;
  position: sticky;
  width: 100%;
  z-index: 49;
}
.nav-content .module-nav[data-v-d2b0f33a] {
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 10px 50px rgba(220, 220, 228, 0.23);
  transition: all 0.1s linear;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  display: flex;
  font-size: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(155, 158, 169, var(--tw-text-opacity));
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-content .module-nav .module-nav-item:first-child.active[data-v-d2b0f33a],
.nav-content .module-nav .module-nav-item:last-child.active[data-v-d2b0f33a] {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 84, 254, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.nav-content .module-nav .module-nav-item:first-child.active[data-v-d2b0f33a] {
  border-radius: 4px 0 0 4px;
}
.nav-content .module-nav .module-nav-item[data-v-d2b0f33a] {
  flex: 1 1 0%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lx-module-section .module[data-v-d2b0f33a]:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 249, 255, var(--tw-bg-opacity));
}
@media (min-width: 1024px) {
  .lx-module-section .module:nth-child(odd) .function-wrap[data-v-d2b0f33a] {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1024px) {
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
.max-w-7xl {
  max-width: 80rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
@media (min-width: 1024px) {
  .lx-list[data-v-47b03c32] {
    text-align: left;
    width: 30rem;
  }
}
@media (min-width: 1024px) {
  .lx-list .lx-list-header[data-v-47b03c32] {
    font-size: 2.25rem;
  }
}
.lx-list .lx-list-header[data-v-47b03c32] {
  font-weight: 500;
  font-size: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
  letter-spacing: 1px;
}
@media (min-width: 1024px) {
  .lx-list .lx-list-description[data-v-47b03c32] {
    font-size: 1rem;
  }
}
.lx-list .lx-list-description[data-v-47b03c32] {
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
  letter-spacing: 1px;
}
dd,
dl,
h2,
h3,
p {
  margin: 0;
}
@media (min-width: 1024px) {
  .lg\:hidden {
    display: none;
  }
}
.mt-4 {
  margin-top: 1rem;
}
img,
video {
  max-width: 100%;
  height: auto;
}
iframe,
img,
svg,
video {
  display: block;
  vertical-align: middle;
}
@media (min-width: 1024px) {
  .lx-list .lx-list-container[data-v-47b03c32] {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.lx-list .lx-list-container[data-v-47b03c32] {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}
@media (min-width: 1024px) {
  .lx-list-item[data-v-30e904fc] {
    font-size: 1.125rem;
  }
}
.lx-list-item[data-v-30e904fc] {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75rem;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .en-main .lx-list-item .lx-list-item.no-description[data-v-30e904fc],
  .lx-list-item .en-main .lx-list-item.no-description[data-v-30e904fc],
  .lx-list-item .lx-list-item-detail[data-v-30e904fc] {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}
.en-main .lx-list-item .lx-list-item.no-description[data-v-30e904fc],
.lx-list-item .en-main .lx-list-item.no-description[data-v-30e904fc],
.lx-list-item .lx-list-item-detail[data-v-30e904fc] {
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 22px;
  --tw-text-opacity: 1;
  color: rgba(92, 94, 102, var(--tw-text-opacity));
}
.lx-list .bg-grey[data-v-47b03c32] {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.lx-list .video[data-v-47b03c32] {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  height: 39.75rem;
  overflow: hidden;
  position: fixed;
  width: 60rem;
}
@media (min-width: 769px) {
  .md\:block {
    display: block;
  }
}
.lx-list .close-video[data-v-47b03c32] {
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-size: 1.875rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 2rem;
  z-index: 1006;
  background: rgba(0, 0, 0, 0.45);
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lx-list-item[data-v-30e904fc]:before {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 84, 254, var(--tw-bg-opacity));
  border-radius: 50%;
  position: absolute;
  left: -1.25rem;
  top: 11px;
  content: "";
  width: 6px;
  height: 6px;
  box-shadow: 0 0 0 5px rgba(37, 84, 254, 0.1);
}
html:not(.no-js) [data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: none;
}
html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}
@media (min-width: 1024px) {
  .lg\:w-160 {
    width: 40rem;
  }
}
@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }
}
@media (min-width: 1024px) {
  .lx-module-section .module[data-v-d2b0f33a] {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}
.bg-colorBg {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 249, 255, var(--tw-bg-opacity));
}
@media (min-width: 1024px) {
  .\!lg\:leading-\[54px\] {
    line-height: 54px !important;
  }
}
@media (min-width: 1024px) {
  .lg\:text-4xl {
    font-size: 2.25rem;
  }
}
.text-colorPrimary-100 {
  --tw-text-opacity: 1;
  color: rgba(37, 84, 254, var(--tw-text-opacity));
}
.font-medium {
  font-weight: 500;
}
@media (min-width: 1024px) {
  .lg\:text-textTitle {
    --tw-text-opacity: 1;
    color: rgba(26, 32, 53, var(--tw-text-opacity));
  }
}
@media (min-width: 1024px) {
  .lg\:mt-3 {
    margin-top: 0.75rem;
  }
}
@media (min-width: 1024px) {
  .lg\:text-base {
    font-size: 1rem;
  }
}
@media (min-width: 1024px) {
  .lg\:font-normal {
    font-weight: 400;
  }
}
@media (min-width: 1024px) {
  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
  }
}
@media (min-width: 1024px) {
  .lg\:py-30 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}
@media (min-width: 1024px) {
  .product-rumor[data-v-281394a7] {
    margin-top: 0;
    padding: 3rem 4rem;
    width: 40rem;
  }
}
.product-rumor[data-v-281394a7] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1rem;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 16px 0 rgba(180, 189, 221, 0.1),
    0 6px 14px 0 hsla(0, 0%, 100%, 0.3), 0 10px 10px 0 rgba(180, 189, 221, 0.2);
  transform-style: preserve-3d;
  text-align: left;
}
@media (min-width: 1024px) {
  .product-rumor .title[data-v-281394a7] {
    font-size: 22px;
    line-height: 1;
  }
}
.product-rumor .title[data-v-281394a7] {
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .product-rumor .avatar[data-v-281394a7] {
    height: 4.5rem;
    width: 4.5rem;
    border: 1.5px solid #e6e8f0;
  }
}
.product-rumor .avatar[data-v-281394a7] {
  border-radius: 50%;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .lg\:ml-5 {
    margin-left: 1.25rem;
  }
}
@media (min-width: 1024px) {
  .lg\:font-medium {
    font-weight: 500;
  }
}
.text-textTitle {
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .lg\:mt-2 {
    margin-top: 0.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:text-sm {
    font-size: 0.875rem;
  }
}
.text-textSup {
  --tw-text-opacity: 1;
  color: rgba(155, 158, 169, var(--tw-text-opacity));
}
</style>
