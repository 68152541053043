<template>
  <div class="ad">
    <div data-v-ac1bae46="" data-v-109a143a="" class="lx-track-module">
      <div data-v-a32e13b0="" data-v-ac1bae46="" class="lx-banner-container">
        <div
          data-v-a32e13b0=""
          class="lx-content-container items-center mt-8 mb-10 lg:flex-row lg:justify-between lg:items-center lg:mt-10"
        >
          <section
            data-v-a32e13b0=""
            class="text-center mb-4 lg:text-left lg:mb-0"
          >
            <!---->
            <h3 data-v-a32e13b0="" class="lx-banner-title">
              <span data-v-a32e13b0="" class=""
                >高效益管理亚马逊广告，用百宸ERP</span
              >
            </h3>
            <!---->
            <p
              data-v-a32e13b0=""
              class="text-xs lg:text-lg font-light text-textContent mt-[6px] mb-8"
            >
              广告效果有迹可循，让每一笔广告费都花在刀刃上！
            </p>
            <el-button type="primary" size="large" @click="tryout()"
              >免费试用</el-button
            >
            <!---->
            <p data-v-a32e13b0="" class="source-tip" style="display: none">
              来源：艾瑞咨询《2024年中国跨境电商软件服务行业报告》
            </p>
          </section>
          <section data-v-a32e13b0="" class="banner-img">
            <img
              data-v-a32e13b0=""
              width="100%"
              src="@/assets/92ccb6f3a8b841bac2addd83d91cd916.png"
              alt="跨境电商精细化财务管理_业财一体化管理系统-百宸ERP"
              class="banner-main-img"
            />
            <!---->
            <!---->
            <!---->
          </section>
        </div>
      </div>
      <div data-v-ac1bae46="" class="lx-module-section">
        <div data-v-ac1bae46="" class="bg-colorBg">
          <div
            data-v-ac1bae46=""
            class="my-12 max-w-7xl mx-auto px-[15px] lg:px-0 lg:my-30"
          >
            <div
              data-v-57c9ab6d=""
              data-v-ac1bae46=""
              class="flex justify-center flex-col items-center lx-large-title"
            >
              <h3
                data-v-57c9ab6d=""
                class="lx-title w-full"
                style="text-align: center"
              >
                亚马逊广告精细化智能管理
              </h3>
              <div
                data-v-57c9ab6d=""
                class="max-w-840px text-sm px-15px lg:text-lg lg:px-0 font-light text-textContent text-center"
              >
                百宸ERP提供广告精细化策略，全面开展精细化广告投放与分析，实现广告效益倍增
              </div>
            </div>
            <div
              data-v-ac1bae46=""
              class="w-full grid grid-cols-2 gap-4 mt-8 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-10 lg:mt-16"
            >
              <div
                data-v-73a06f5c=""
                data-v-ac1bae46=""
                class="core-card items-start"
              >
                <div data-v-73a06f5c="" class="img-container">
                  <el-icon :size="50" color="#409efc"> <Setting /></el-icon>
                </div>
                <p
                  data-v-73a06f5c=""
                  class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
                >
                  一站式管理广告
                </p>
                <p
                  data-v-73a06f5c=""
                  class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
                >
                  全面管理SP、SB、SD等所有广告，一站式解决广告操作
                </p>
              </div>
              <div
                data-v-73a06f5c=""
                data-v-ac1bae46=""
                class="core-card items-start"
              >
                <div data-v-73a06f5c="" class="img-container">
                  <el-icon :size="50" color="#409efc">
                    <RefreshRight
                  /></el-icon>
                </div>
                <p
                  data-v-73a06f5c=""
                  class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
                >
                  小时级别数据
                </p>
                <p
                  data-v-73a06f5c=""
                  class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
                >
                  提供小时粒度广告数据报告，助力搭建精细化广告策略
                </p>
              </div>
              <div
                data-v-73a06f5c=""
                data-v-ac1bae46=""
                class="core-card items-start"
              >
                <div data-v-73a06f5c="" class="img-container">
                  <el-icon :size="50" color="#409efc"> <Share /></el-icon>
                </div>
                <p
                  data-v-73a06f5c=""
                  class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
                >
                  全方位分时策略
                </p>
                <p
                  data-v-73a06f5c=""
                  class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
                >
                  自动按预设调整预算、竞价、状态、广告位、SP顶级预算等，提升广告收益
                </p>
              </div>
              <div
                data-v-73a06f5c=""
                data-v-ac1bae46=""
                class="core-card items-start"
              >
                <div data-v-73a06f5c="" class="img-container">
                  <el-icon :size="50" color="#409efc"> <EditPen /></el-icon>
                </div>
                <p
                  data-v-73a06f5c=""
                  class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
                >
                  自动规则
                </p>
                <p
                  data-v-73a06f5c=""
                  class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
                >
                  自定义单个关键词/用户搜索词等的执行规则，自动提高或者降低预算、出价
                </p>
              </div>
              <div
                data-v-73a06f5c=""
                data-v-ac1bae46=""
                class="core-card items-start"
              >
                <div data-v-73a06f5c="" class="img-container">
                  <el-icon :size="50" color="#409efc"> <Tickets /></el-icon>
                </div>
                <p
                  data-v-73a06f5c=""
                  class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
                >
                  精准引流关键词
                </p>
                <p
                  data-v-73a06f5c=""
                  class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
                >
                  精准溯源分析实现广告ASIN引流的关键词及广告活动
                </p>
              </div>
              <div
                data-v-73a06f5c=""
                data-v-ac1bae46=""
                class="core-card items-start"
              >
                <div data-v-73a06f5c="" class="img-container">
                  <el-icon :size="50" color="#409efc"> <Edit /></el-icon>
                </div>
                <p
                  data-v-73a06f5c=""
                  class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
                >
                  智能轻松管预算
                </p>
                <p
                  data-v-73a06f5c=""
                  class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
                >
                  支持递增预算、预算分析、告警，助力高效管理预算，全天候曝光
                </p>
              </div>
              <div
                data-v-73a06f5c=""
                data-v-ac1bae46=""
                class="core-card items-start"
              >
                <div data-v-73a06f5c="" class="img-container">
                  <el-icon :size="50" color="#409efc"> <Histogram /></el-icon>
                </div>
                <p
                  data-v-73a06f5c=""
                  class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
                >
                  全局分析和概览
                </p>
                <p
                  data-v-73a06f5c=""
                  class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
                >
                  自动提供多维可视化广告报表，便于卖家明晰广告效果，快速调整广告策略
                </p>
              </div>
              <div
                data-v-73a06f5c=""
                data-v-ac1bae46=""
                class="core-card items-start"
              >
                <div data-v-73a06f5c="" class="img-container">
                  <el-icon :size="50" color="#409efc"> <Position /></el-icon>
                </div>
                <p
                  data-v-73a06f5c=""
                  class="font-medium mt-3 text-sm lg:mt-4 lg:text-22px text-textTitle text-center"
                >
                  系统易用上手快
                </p>
                <p
                  data-v-73a06f5c=""
                  class="text-sm font-normal mt-3.5 text-textSubtitle text-left hidden leading-6 lg:block"
                >
                  多维贴合业务实际的报表供卖家快速取用，操作体验媲美后台，高效投放
                </p>
              </div>
            </div>
          </div>
        </div>
        <div data-v-ac1bae46="" class="module">
          <div
            data-v-ac1bae46=""
            data-aos="fade-up"
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0 aos-init aos-animate"
          >
            <div data-v-47b03c32="" data-v-ac1bae46="" class="lx-list">
              <header data-v-47b03c32="" class="lx-list-header">
                多维度数据，全局概览效果
              </header>
              <p data-v-47b03c32="" class="lx-list-description">
                <span data-v-ac1bae46="" data-v-47b03c32=""
                  >自动提供多维可视化广告报表，便于卖家明晰广告效果</span
                >
              </p>
              <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                <img
                  data-v-47b03c32=""
                  width="100%"
                  src="@/assets/64145334fb27a54d9700202a7298f18d.png"
                  alt="多维度数据，全局概览效果"
                />
              </div>
              <ul data-v-47b03c32="" class="lx-list-container">
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  16+广告数据仪表盘
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    集成所有广告类型核心数据，一览广告费投入集中度、转化效果
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  全局概览广告效果
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    自动形成按店铺、广告位等维度数据，以趋势图展示，一目了然
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  店铺业绩表现分析
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    为卖家提供店铺维度分析的数据源，以判断店铺内广告投放变化
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  按需快速获取数据
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    支持自定义重点关注的报告、获取频率与时间段，自动回溯报告
                  </p>
                </li>
              </ul>
              <div
                data-v-47b03c32=""
                class="space-x-4 flex"
                style="font-size: 0px"
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div data-v-47b03c32="" class="bg-grey" style="display: none">
                <div data-v-47b03c32="" class="video md:block">
                  <div data-v-47b03c32="" id="lx-player-79">
                    <i
                      data-v-47b03c32=""
                      class="close-video iconfont icon-close"
                    ></i>
                  </div>
                  <div
                    data-v-47b03c32=""
                    class="video-text flex justify-center items-center h-24"
                  >
                    <p
                      data-v-47b03c32=""
                      class="text-2xl font-medium inline-block mr-4"
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      data-v-47b03c32=""
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-ac1bae46="" class="w-full hidden lg:block lg:w-160">
              <img
                data-v-ac1bae46=""
                width="100%"
                src="@/assets/64145334fb27a54d9700202a7298f18d.png"
                alt="自动提供多维可视化广告报表，便于卖家明晰广告效果"
              />
            </div>
          </div>
        </div>
        <div data-v-ac1bae46="" class="module">
          <div
            data-v-ac1bae46=""
            data-aos="fade-up"
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0 aos-init aos-animate"
          >
            <div data-v-47b03c32="" data-v-ac1bae46="" class="lx-list">
              <header data-v-47b03c32="" class="lx-list-header">
                深度经营分析，助力决策
              </header>
              <p data-v-47b03c32="" class="lx-list-description">
                <span data-v-ac1bae46="" data-v-47b03c32=""
                  >多维度数据助力分析，深挖广告表现根源，实现降本增效</span
                >
              </p>
              <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                <img
                  data-v-47b03c32=""
                  width="100%"
                  src="@/assets/c3ecafb679cbac3fc72614f2a4e413a6.png"
                  alt="深度经营分析，助力决策"
                />
              </div>
              <ul data-v-47b03c32="" class="lx-list-container">
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  透视分析广告效果
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    广告ASIN、广告关键词、用户搜索词互相透视分析，发现优质关键词等
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  OtherASIN报表
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    支持查看广告ASIN的成交情况，溯源分析促进成交的关键词及广告活动
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  多维数据分析

                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    提供星期维度的同比、环比分析，小时级广告数据，更精准把控预算
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  广告日志溯源分析
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    支持自动同步运营操作日志，让每一笔广告费用都有迹可循
                  </p>
                </li>
              </ul>
              <div
                data-v-47b03c32=""
                class="space-x-4 flex"
                style="font-size: 0px"
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div data-v-47b03c32="" class="bg-grey" style="display: none">
                <div data-v-47b03c32="" class="video md:block">
                  <div data-v-47b03c32="" id="lx-player-80">
                    <i
                      data-v-47b03c32=""
                      class="close-video iconfont icon-close"
                    ></i>
                  </div>
                  <div
                    data-v-47b03c32=""
                    class="video-text flex justify-center items-center h-24"
                  >
                    <p
                      data-v-47b03c32=""
                      class="text-2xl font-medium inline-block mr-4"
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      data-v-47b03c32=""
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-ac1bae46="" class="w-full hidden lg:block lg:w-160">
              <img
                data-v-ac1bae46=""
                width="100%"
                src="@/assets/c3ecafb679cbac3fc72614f2a4e413a6.png"
                alt="多维度数据助力分析，深挖广告表现根源，实现降本增效"
              />
            </div>
          </div>
        </div>
        <div data-v-ac1bae46="" class="module">
          <div
            data-v-ac1bae46=""
            data-aos="fade-up"
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0 aos-init aos-animate"
          >
            <div data-v-47b03c32="" data-v-ac1bae46="" class="lx-list">
              <header data-v-47b03c32="" class="lx-list-header">
                构建优质关键词矩阵
              </header>
              <p data-v-47b03c32="" class="lx-list-description">
                <span data-v-ac1bae46="" data-v-47b03c32=""
                  >快速获取优质出单词，助力抢占广告位首页</span
                >
              </p>
              <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                <img
                  data-v-47b03c32=""
                  width="100%"
                  src="@/assets/86ff75ea7e280b38143e6270c46241e5-1.png"
                  alt="构建优质关键词矩阵"
                />
              </div>
              <ul data-v-47b03c32="" class="lx-list-container">
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  重复关键词表
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    可查看同一关键词在不同广告组、匹配方式下的表现，以便优化关键词的设置
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  出单词库分析

                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    自动分析用户搜索词的词频，快速汇总、对比、细分溯源搜索词的广告表现
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  ASIN反查

                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    基于亚马逊的数据源，快速查询同类目中排名靠前的ASIN流量词，筛选主打关键词
                  </p>
                </li>
              </ul>
              <div
                data-v-47b03c32=""
                class="space-x-4 flex"
                style="font-size: 0px"
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div data-v-47b03c32="" class="bg-grey" style="display: none">
                <div data-v-47b03c32="" class="video md:block">
                  <div data-v-47b03c32="" id="lx-player-81">
                    <i
                      data-v-47b03c32=""
                      class="close-video iconfont icon-close"
                    ></i>
                  </div>
                  <div
                    data-v-47b03c32=""
                    class="video-text flex justify-center items-center h-24"
                  >
                    <p
                      data-v-47b03c32=""
                      class="text-2xl font-medium inline-block mr-4"
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      data-v-47b03c32=""
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-ac1bae46="" class="w-full hidden lg:block lg:w-160">
              <img
                data-v-ac1bae46=""
                width="100%"
                src="@/assets/86ff75ea7e280b38143e6270c46241e5-1.png"
                alt="快速获取优质出单词，助力抢占广告位首页"
              />
            </div>
          </div>
        </div>
        <div data-v-ac1bae46="" class="module">
          <div
            data-v-ac1bae46=""
            data-aos="fade-up"
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0 aos-init aos-animate"
          >
            <div data-v-47b03c32="" data-v-ac1bae46="" class="lx-list">
              <header data-v-47b03c32="" class="lx-list-header">
                广告预算精细管理
              </header>
              <p data-v-47b03c32="" class="lx-list-description">
                <span data-v-ac1bae46="" data-v-47b03c32=""
                  >从预算建议、调整、告警到预算分析，科学合理管理预算</span
                >
              </p>
              <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                <img
                  data-v-47b03c32=""
                  width="100%"
                  src="@/assets/be3ecc96878eed3223a7954ab84c4737.png"
                  alt="广告预算精细管理"
                />
              </div>
              <ul data-v-47b03c32="" class="lx-list-container">
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  建议预算

                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    提供亚马逊建议预算，对应的销售额、ACOS等效果预估，评估预算合理性
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  递增预算

                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    按照预设初始预算、时间段、递增预算范围，自动跟踪广告表现增加预算
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  预算分析

                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    提供每天调整预算的次数、花费、超预算时段等，以供分析并持续优化预算
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  预算告警
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    针对超预算广告活动设置消息推送提醒，实时把控广告预算
                  </p>
                </li>
              </ul>
              <div
                data-v-47b03c32=""
                class="space-x-4 flex"
                style="font-size: 0px"
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div data-v-47b03c32="" class="bg-grey" style="display: none">
                <div data-v-47b03c32="" class="video md:block">
                  <div data-v-47b03c32="" id="lx-player-82">
                    <i
                      data-v-47b03c32=""
                      class="close-video iconfont icon-close"
                    ></i>
                  </div>
                  <div
                    data-v-47b03c32=""
                    class="video-text flex justify-center items-center h-24"
                  >
                    <p
                      data-v-47b03c32=""
                      class="text-2xl font-medium inline-block mr-4"
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      data-v-47b03c32=""
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-ac1bae46="" class="w-full hidden lg:block lg:w-160">
              <img
                data-v-ac1bae46=""
                width="100%"
                src="@/assets/be3ecc96878eed3223a7954ab84c4737.png"
                alt="从预算建议、调整、告警到预算分析，科学合理管理预算"
              />
            </div>
          </div>
        </div>
        <div data-v-ac1bae46="" class="module">
          <div
            data-v-ac1bae46=""
            data-aos="fade-up"
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0 aos-init aos-animate"
          >
            <div data-v-47b03c32="" data-v-ac1bae46="" class="lx-list">
              <header data-v-47b03c32="" class="lx-list-header">
                跨类型一站式管理
              </header>
              <p data-v-47b03c32="" class="lx-list-description">
                <span data-v-ac1bae46="" data-v-47b03c32=""
                  >聚合管理所有类型广告，广告投放全面提效</span
                >
              </p>
              <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                <img
                  data-v-47b03c32=""
                  width="100%"
                  src="@/assets/e16d82e00319b8a5e8fc6985f10ea3ae.png"
                  alt="跨类型一站式管理"
                />
              </div>
              <ul data-v-47b03c32="" class="lx-list-container">
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  集成管理多类型广告

                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    涵盖SP、SB、SD、SBV全广告类型，一站式解决广告投放与管理
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  跨广告类型一键投放
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    一键转投SB、SP、SD广告的关键词或用户搜索词，高效复制成功经验
                  </p>
                </li>
              </ul>
              <div
                data-v-47b03c32=""
                class="space-x-4 flex"
                style="font-size: 0px"
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div data-v-47b03c32="" class="bg-grey" style="display: none">
                <div data-v-47b03c32="" class="video md:block">
                  <div data-v-47b03c32="" id="lx-player-83">
                    <i
                      data-v-47b03c32=""
                      class="close-video iconfont icon-close"
                    ></i>
                  </div>
                  <div
                    data-v-47b03c32=""
                    class="video-text flex justify-center items-center h-24"
                  >
                    <p
                      data-v-47b03c32=""
                      class="text-2xl font-medium inline-block mr-4"
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      data-v-47b03c32=""
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-ac1bae46="" class="w-full hidden lg:block lg:w-160">
              <img
                data-v-ac1bae46=""
                width="100%"
                src="@/assets/e16d82e00319b8a5e8fc6985f10ea3ae.png"
                alt="聚合管理所有类型广告，广告投放全面提效"
              />
            </div>
          </div>
        </div>
        <div data-v-ac1bae46="" class="module">
          <div
            data-v-ac1bae46=""
            data-aos="fade-up"
            class="function-wrap max-w-7xl mx-auto flex flex-col-reverse justify-between items-center px-[15px] lg:flex-row lg:px-0 aos-init aos-animate"
          >
            <div data-v-47b03c32="" data-v-ac1bae46="" class="lx-list">
              <header data-v-47b03c32="" class="lx-list-header">
                抢占时间差，高效益投放
              </header>
              <p data-v-47b03c32="" class="lx-list-description">
                <span data-v-ac1bae46="" data-v-47b03c32=""
                  >多种广告工具助力高效投放广告，获得高流量</span
                >
              </p>
              <div data-v-47b03c32="" class="mt-4 block lg:hidden">
                <img
                  data-v-47b03c32=""
                  width="100%"
                  src="@/assets/338465200079c4728560e1d2fb7337ee.png"
                  alt="抢占时间差，高效益投放"
                />
              </div>
              <ul data-v-47b03c32="" class="lx-list-container">
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  关键词抢位
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    按照预设规则，定时自动调整关键词出价，抢占目标广告位，获取高曝光与转化
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  分时自动优化广告

                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    自动按预设的分时策略调整广告预算、出价、竞价、状态、广告位、SP顶级预算
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  出单时段分析
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    按店铺、MSKU维度统计店铺出单的高峰期和低谷期，为分时策略提供数据支持
                  </p>
                </li>
                <li
                  data-v-30e904fc=""
                  data-v-ac1bae46=""
                  class="lx-list-item"
                  data-v-47b03c32=""
                >
                  自动规则
                  <p data-v-30e904fc="" class="lx-list-item-detail">
                    自定义单个关键词、用户搜索词或广告活动的自动执行规则，提高广告优化效率
                  </p>
                </li>
              </ul>
              <div
                data-v-47b03c32=""
                class="space-x-4 flex"
                style="font-size: 0px"
              >
                <el-button type="primary" size="large" @click="tryout()"
                  >免费试用<el-icon class="el-icon--right"
                    ><ArrowRight /></el-icon
                ></el-button>
                <!---->
                <!---->
              </div>
              <div data-v-47b03c32="" class="bg-grey" style="display: none">
                <div data-v-47b03c32="" class="video md:block">
                  <div data-v-47b03c32="" id="lx-player-84">
                    <i
                      data-v-47b03c32=""
                      class="close-video iconfont icon-close"
                    ></i>
                  </div>
                  <div
                    data-v-47b03c32=""
                    class="video-text flex justify-center items-center h-24"
                  >
                    <p
                      data-v-47b03c32=""
                      class="text-2xl font-medium inline-block mr-4"
                    >
                      百宸ERP，助力实现精细管理和业务增长
                    </p>
                    <button
                      data-v-47b03c32=""
                      type="button"
                      class="el-button h-10 el-button--primary el-button--medium"
                    >
                      <!----><!----><span>立即体验功能</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-ac1bae46="" class="w-full hidden lg:block lg:w-160">
              <img
                data-v-ac1bae46=""
                width="100%"
                src="@/assets/338465200079c4728560e1d2fb7337ee.png"
                alt="多种广告工具助力高效投放广告，获得高流量"
              />
            </div>
          </div>
        </div>
      </div>
      <div data-v-ac1bae46="" class="py-12 lg:py-30">
        <div data-v-ac1bae46="" class="px-15px mb-12 lg:px-0 lg:mb-15">
          <div
            data-v-57c9ab6d=""
            data-v-ac1bae46=""
            class="flex justify-center flex-col items-center lx-large-title aos-init aos-animate"
            data-aos="fade-up"
          >
            <h3
              data-v-57c9ab6d=""
              class="lx-title w-full"
              style="text-align: center"
            >
              <span class="highlight-text">500000+</span>跨境企业的一致选择
            </h3>
            <div
              data-v-57c9ab6d=""
              class="max-w-840px text-sm px-15px lg:text-lg lg:px-0 font-light text-textContent text-center"
            >
              为数千家亿级跨境卖家提供精细化运营的数字化解决方案
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElIcon } from "element-plus";
import {
  Setting,
  RefreshRight,
  Share,
  EditPen,
  Tickets,
  Histogram,
  Position,
  Edit,
  ArrowRight,
} from "@element-plus/icons-vue";
</script>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    tryout() {
      window.open("http://erp.insmeta.net/");
    },
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .lx-banner-container[data-v-a32e13b0] {
    min-height: 33.75rem;
    padding-left: 0;
    padding-right: 0;
  }
}
.lx-banner-container[data-v-a32e13b0] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: flex;
  width: 100%;
}
@media (min-width: 1024px) {
  .lg\:mt-10 {
    margin-top: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .lg\:items-center {
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.lx-content-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  width: 100%;
}
@media (min-width: 1024px) {
  .lg\:text-left {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }
}
.lx-banner-container[data-v-a32e13b0] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (min-width: 1024px) {
  .lx-banner-title[data-v-a32e13b0] {
    font-size: 2.25rem;
    line-height: 3.125rem;
  }
}
.lx-banner-title[data-v-a32e13b0] {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(58, 64, 87, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .lg\:text-lg {
    font-size: 1.125rem;
  }
}
.text-textContent {
  --tw-text-opacity: 1;
  color: rgba(92, 94, 102, var(--tw-text-opacity));
}
.mb-8 {
  margin-bottom: 2rem;
}
.mt-\[6px\] {
  margin-top: 6px;
}
.font-light {
  font-weight: 300;
}
.lx-banner-container .source-tip[data-v-a32e13b0] {
  margin-top: 64px;
  color: #a2aeca;
  font-size: 14px;
  padding-bottom: 50px;
}
@media (min-width: 1024px) {
  .lx-banner-container .banner-img[data-v-a32e13b0] {
    border-radius: 1.5rem;
    margin-left: 1.25rem;
    min-width: 50rem;
    width: 50rem;
  }
}
.lx-banner-container .banner-img[data-v-a32e13b0] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1rem;
  border-radius: 1.5rem;
  max-width: 50rem;
  position: relative;
  width: 100%;
}
@media (min-width: 1024px) {
  .lx-banner-container .banner-img .banner-main-img[data-v-a32e13b0] {
    border-radius: 1.5rem;
  }
}
img,
video {
  max-width: 100%;
  height: auto;
}
iframe,
img,
svg,
video {
  display: block;
  vertical-align: middle;
}
.lx-module-section[data-v-ac1bae46] {
  display: flex;
  flex-direction: column;
}
.bg-colorBg {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 249, 255, var(--tw-bg-opacity));
}
@media (min-width: 1024px) {
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .lg\:my-30 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
}
.max-w-7xl {
  max-width: 80rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
@media (min-width: 1024px) {
  .lx-title[data-v-57c9ab6d] {
    font-size: 2.25rem;
    line-height: 50px;
    margin-bottom: 0.5rem;
  }
}
.lx-title[data-v-57c9ab6d] {
  font-weight: 500;
  max-width: 840px;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
  letter-spacing: 1px;
}
@media (min-width: 1024px) {
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .lg\:gap-y-10 {
    -webkit-row-gap: 2.5rem;
    -moz-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:gap-x-8 {
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
}
@media (min-width: 1024px) {
  .lg\:mt-16 {
    margin-top: 4rem;
  }
}
.w-full {
  width: 100%;
}
.grid {
  display: grid;
}
@media (min-width: 1024px) {
  .core-card[data-v-73a06f5c] {
    padding: 1.5rem 1.5rem 2rem;
  }
}
.core-card[data-v-73a06f5c] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(180, 189, 221, 0.08),
    0 6px 14px hsla(0, 0%, 100%, 0.3), 0 12px 20px rgba(180, 189, 221, 0.2);
}
.items-start {
  align-items: flex-start;
}
@media (min-width: 1024px) {
  .lg\:mt-4 {
    margin-top: 1rem;
  }
}
@media (min-width: 1024px) {
  .lg\:text-22px {
    font-size: 22px;
    line-height: 1;
  }
}
@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }
}
.text-textSubtitle {
  --tw-text-opacity: 1;
  color: rgba(58, 64, 87, var(--tw-text-opacity));
}
.text-left {
  text-align: left;
}
.mt-3\.5 {
  margin-top: 0.875rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.font-normal {
  font-weight: 400;
}
dd,
dl,
h2,
h3,
p {
  margin: 0;
}
.lx-module-section .module[data-v-ac1bae46]:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  .lx-module-section .module[data-v-ac1bae46] {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}
.lx-module-section .module[data-v-ac1bae46] {
  width: 100%;
}
.justify-between {
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .lx-list[data-v-47b03c32] {
    margin-top: 0;
    width: 30rem;
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .lx-list .lx-list-header[data-v-47b03c32] {
    font-size: 2.25rem;
  }
}
.lx-list .lx-list-header[data-v-47b03c32] {
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
  letter-spacing: 1px;
}
@media (min-width: 1024px) {
  .lx-list .lx-list-description[data-v-47b03c32] {
    font-size: 1rem;
  }
}
.lx-list .lx-list-description[data-v-47b03c32] {
  font-weight: 400;
  margin-top: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
  letter-spacing: 1px;
}
@media (min-width: 1024px) {
  .lg\:hidden {
    display: none;
  }
}
.mt-4 {
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .lx-list .lx-list-container[data-v-47b03c32] {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.lx-list .lx-list-container[data-v-47b03c32] {
  padding-left: 1.5rem;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .lx-list-item[data-v-30e904fc] {
    font-size: 1.125rem;
  }
}
.lx-list-item[data-v-30e904fc] {
  font-weight: 500;
  line-height: 1.75rem;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(26, 32, 53, var(--tw-text-opacity));
}
.lx-list-item[data-v-30e904fc]:before {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 84, 254, var(--tw-bg-opacity));
  border-radius: 50%;
  position: absolute;
  left: -1.25rem;
  top: 11px;
  content: "";
  width: 6px;
  height: 6px;
  box-shadow: 0 0 0 5px rgba(37, 84, 254, 0.1);
}
@media (min-width: 1024px) {
  .en-main .lx-list-item .lx-list-item.no-description[data-v-30e904fc],
  .lx-list-item .en-main .lx-list-item.no-description[data-v-30e904fc],
  .lx-list-item .lx-list-item-detail[data-v-30e904fc] {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}
.en-main .lx-list-item .lx-list-item.no-description[data-v-30e904fc],
.lx-list-item .en-main .lx-list-item.no-description[data-v-30e904fc],
.lx-list-item .lx-list-item-detail[data-v-30e904fc] {
  font-weight: 300;
  --tw-text-opacity: 1;
  color: rgba(92, 94, 102, var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .lg\:w-160 {
    width: 40rem;
  }
}
.lx-module-section .module[data-v-ac1bae46]:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 249, 255, var(--tw-bg-opacity));
}
@media (min-width: 1024px) {
  .lx-module-section .module:nth-child(odd) .function-wrap[data-v-ac1bae46] {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1024px) {
  .lg\:py-30 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}
</style>